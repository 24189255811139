









































































































.backgrondRoade {
  width: 0.6rem;
  background: black;
  height: 0.6rem;
}

.duihaoImg {
  width: 0.3rem;
  margin-left: 5.6rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
}

.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}

.body1Style {
  padding: 0.3rem;
}


.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

/* ---------20200411[start]------- */
.layoutBox {
	height: 100vh;
	margin-top: 0.2rem;
	min-height:91.8vh;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0 0.2rem;
	font-family: PingFangSC-Regular;
}

.list-radio-item {
	padding: .2rem 0;
	margin: 0 0.2rem;
	height: 1.2rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.label {
		color: #333333;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon {
		width: .7rem;
		height: .7rem;
		margin-right: .15rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.right_img {
		img {
			width: 0.14rem;
			height: 0.24rem;
		}
	}
}
.comBtnStyle {
	height: 0.8rem;
	line-height: 0.8rem;
	margin: 0 0.2rem;
}
/* ---------20200411[end]------- */

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;